import { graphql, useStaticQuery } from 'gatsby';

// get pages that should be displayed in the footer (in the top section)
const useFooterUpPages = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { frontmatter: { footerUp: { eq: true } } }
        sort: { fields: slug }
      ) {
        nodes {
          slug
          frontmatter {
            slug
            title
            link
            icon
          }
        }
      }
    }
  `);

  return data.allMdx.nodes.map(page => ({
    slug: page.frontmatter.slug,
    title: page.frontmatter.title,
    link: page.frontmatter.link,
    icon: page.frontmatter.icon,
  }));
};

export default useFooterUpPages;
