import React from 'react';
import { Link } from 'gatsby';
import useHeaderPages from '../hooks/use-header-pages';

const Header = () => {
  const pages = useHeaderPages();

  return (
    <header className="lg:pr-12 lg:pl-12 pr-6 pl-6 lg:mt-12 mt-6 flex justify-between">
      <div className="lg:w-56 w-32">
        <Link to="/" className="block">
          <img
            src="/images/logo.svg"
            alt="Zur Startseite (Logo der Bundesregierung)"
          />
        </Link>
      </div>
      <nav aria-labelledby="headerNavTitle">
        <p id="headerNavTitle" className="sr-only">
          Hauptnavigation
        </p>
        <ul className="items-center lg:mt-2 lg:flex hidden">
          {pages.map((page, index) => (
            <li key={`header-${index}`} className="flex items-center ml-10">
              <Link
                to={`/${page.slug}`}
                className="uppercase font-bold flex items-center"
              >
                <i
                  className={`icon-${page.icon} text-2xl`}
                  aria-hidden="true"
                ></i>
                <span className="ml-4">{page.link}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};
export default Header;
