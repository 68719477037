import React from 'react';
import { Helmet } from 'react-helmet';
import useStaticMetadata from '../hooks/use-sitemetadata';

const SEO = ({ pageTitle, pageUrl }) => {
  const { title, description, baseUrl } = useStaticMetadata();
  const siteTitle = pageTitle ? `${pageTitle} - ${title}` : title;
  const url = pageUrl ? `${baseUrl}${pageUrl}` : baseUrl;

  return (
    <Helmet>
      <html lang="de" />
      <title>{siteTitle}</title>
      <meta name="description" content={description} />
      <meta name="generator" content="Gatsby.js"></meta>
      <meta name="robots" content="index, follow"></meta>
      <meta name="keywords" content={siteTitle}></meta>
      <meta name="og:title" content={siteTitle} />
      <meta name="og:description" content={description} />
      <meta name="og:type" content="website" />
      <meta property="og:site_name" content={siteTitle}></meta>
      <meta property="og:locale" content="de_DE"></meta>
      <meta property="og:url" content={url}></meta>
      <link rel="canonical" content={url}></link>
    </Helmet>
  );
};

export default SEO;
