import React from 'react';
import { Link } from 'gatsby';
import useStaticMetadata from '../hooks/use-sitemetadata';
import useFooterUpPages from '../hooks/use-footer-top-pages';
import useFooterDownPages from '../hooks/use-footer-bottom-pages';

const Footer = () => {
  const { copyright } = useStaticMetadata();
  const footerUpPages = useFooterUpPages();
  const footerDownPages = useFooterDownPages();
  // TODO: get final links
  const socialMedia = [
    {
      text:
        'Externer Link: Zur Facebook-Seite der Bundesregierung (Öffnet neues Fenster)',
      icon: 'facebook',
      link: 'http://www.facebook.com/bundesregierung',
    },
    {
      text:
        'Externer Link: Zum Instagram-Account der Bundeskanzlerin (Öffnet neues Fenster)',
      icon: 'insta',
      link: 'https://www.instagram.com/bundeskanzlerin/',
    },
    {
      text:
        'Externer Link: Zum Twitter-Kanal des Sprechers der Bundesregierung, Steffen Seibert (Öffnet neues Fenster)',
      icon: 'twitter',
      link: 'https://twitter.com/RegSprecher',
    },
    {
      text:
        'Externer Link: Zum YouTube-Kanal der Bundesregierung (Öffnet neues Fenster)',
      icon: 'youtube',
      link: 'http://www.youtube.com/bundesregierung',
    },
  ];

  return (
    <footer className="lg:p-12 p-6 lg:mt-12 mt-6">
      <nav aria-labelledby="socialmediaTitle">
        <h2
          id="socialmediaTitle"
          className="uppercase text-center text-xlarge font-bold"
        >
          Folge uns
        </h2>
        <ul className="flex items-center justify-center">
          {socialMedia.map((social, index) => (
            <li
              key={`socialmedia-${index}`}
              className="flex items-center mt-3 mr-4 lg:mr-6 ml-4 lg:ml-6"
            >
              <a
                className="text-4xl lg:text-5xl"
                href={social.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={`icon-${social.icon}`} aria-hidden="true"></i>
                <span className="sr-only">{social.text}</span>
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <nav
        className="flex justify-center mt-4 lg:mt-8"
        aria-labelledby="footerNavTop"
      >
        <h2 id="footerNavTop" className="sr-only">
          Fußnavigation
        </h2>
        <ul className="flex flex-col lg:flex-row items-center justify-center">
          {footerUpPages.map((page, index) => (
            <li
              key={`footer-up-${index}`}
              className="flex items-center mt-8 mr-0 lg:mr-8 ml-0 lg:ml-8"
            >
              <Link
                to={`/${page.slug}`}
                className="uppercase font-bold flex items-center"
              >
                <i
                  className={`icon-${page.icon} text-2xl`}
                  aria-hidden="true"
                ></i>
                <span className="ml-3">{page.link}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <nav
        className="flex justify-center border-t border-black mt-8 lg:mt-16 mb-6 pt-6"
        aria-labelledby="footerNavBottom"
      >
        <h2 id="footerNavBottom" className="sr-only">
          Metanavigation
        </h2>
        <ul className="flex flex-col lg:flex-row items-center justify-center">
          {footerDownPages.map((page, index) => (
            <li
              key={`footer-down-${index}`}
              className="flex items-center mt-3 mr-0 lg:mr-5 ml-0 lg:ml-5"
            >
              <Link to={`/${page.slug}`} className="uppercase font-bold">
                {page.link}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className="text-center text-xl font-medium">
        <small>{copyright}</small>
      </div>
    </footer>
  );
};
export default Footer;
