import { graphql, useStaticQuery } from 'gatsby';

// get pages that should be displayed in the footer (in the bottom section)
const useFooterDownPages = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(filter: { frontmatter: { footerDown: { eq: true } } }) {
        nodes {
          frontmatter {
            slug
            title
            link
          }
        }
      }
    }
  `);

  return data.allMdx.nodes.map(page => ({
    slug: page.frontmatter.slug,
    title: page.frontmatter.title,
    link: page.frontmatter.link,
  }));
};

export default useFooterDownPages;
